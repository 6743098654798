<template>
  <div class="content">
    <div class="topbar">
      <div class="topbarText" :style="{backgroundImage: 'url(' + require('../assets/topbar_bg.png') + ')'}">
        <div>
          <img :src="require('../assets/logo.png')" style="width: 6rem; margin-bottom: 1rem; margin-top: 2rem;"/>
        </div>
        <div>
          帮瀛第三方资助(TPF)
        </div>
        <div>
          为争议标的<b>5000万元以上</b>的诉讼、仲裁当事人
        </div>
        <div>
          提供<b>丰沛的资金支持</b>
        </div>
        <div>
          及<b>专业的争议解决综合管理服务</b>
        </div>
        <div class="lineSmall"></div>
        <div class="rightNews">
          <a href="https://mp.weixin.qq.com/s/-lUV9HYQldxHTS2ox2ZLQA" target="_blank"><div class="rightNewItem">帮瀛纠纷解决机构诉讼投资业务(TPF)规则</div></a>
          <a href="http://newstj.bangying.org/" target="_blank"><div class="rightNewItem">诉讼投资与公序良俗</div></a>
        </div>
      </div>
    </div>
    <div class="profile">
      <div class="pHead">
        PROFILE
      </div>
      <div class="pSub">
        帮瀛整合型高品质法律服务定制商
      </div>
      <div class="pDec">
        帮瀛成立于2015年，是国内首家专业从事民商事诉讼、行政诉讼、刑事诉讼、仲裁等重大纠纷解决的成本投资、律师匹配、项目管理的整合型高品质法律服务定制商。
      </div>
      <div class="pDec">
        2017年1月，帮瀛获得由俞敏洪和盛希泰联合创办的洪泰基金领投的A轮融资。
      </div>
      <div class="pDec" style="margin-top: 1rem">
        <b>帮瀛「诉讼投资」</b>(第三方资助)，将债权争议视为可投资的潜在资产，根本上缓解纠纷解决过程中当事人或律师的成本压力；同时，帮瀛为客户加入项目管理服务，帮助客户遴选律师，组建一个法律专业水平高、综合项目管理能力强的跨界服务大团队，让客户坐享项目评估、律师遴选、专家论证、模拟法庭、模拟仲裁庭、项目管理、人工智能综合服务，最终实现享受跨专业、跨能力、跨地域、跨阶层的资源整合。
      </div>
      <div class="pDec">
        帮瀛结合自主开发的<b>「多元化纠纷解决数字平台」</b>，能够做到对批量案件进行全线上分流解决，当事人通过线上达成调解协议，并将未主动还款的项目分散约定到全国各地互联网仲裁机构进行速裁，生成有效仲裁裁决直接用于银行账户划扣、强制执行，形成司法流水线，实现线上集中、管辖分散的分流处理；<b>「纠纷解决智能平台」</b>，能够做到在极短的时间内，深入分析评估争议事项，并根据具体情况迅速匹配最合适的律师、流程化管理诉讼项目，在不同纠纷解决阶段为大型中央企业、商业银行、证券公司、资产管理公司等机构以及个人当事人，提供诉讼投资、项目管理等服务。
      </div>
    </div>
    <div class="timeInfo">
      <div class="timeImg" :style="{backgroundImage: 'url(' + require('../assets/bg2.png') + ')'}">
        <div class="timeText" style="position: relative; top: 5rem">
          <div class="timeHead">
            5TH
          </div>
          <div class="timeHead">
            ANNIVERSARY
          </div>
          <div class="timeSub" style="margin: 0.5rem 0">
            帮瀛五周年
          </div>
          <div style="font-size: 0.75rem">
            2015.07.08 —— 2020.07.08
          </div>
          <div class="timeNum">
            <div class="timeNumLeft">
              <div class="timeNumBig">1868<span>个</span></div>
              <div class="timeNumSmall">帮瀛五年收到的有效项目线索</div>
            </div>
            <div class="timeNumRight">
              <div class="timeNumBig">1584.3<span>亿元</span></div>
              <div class="timeNumSmall">五年争议标的总计</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="staticImg">
      <div class="itemImg">
        <img :src="require('../assets/11.png')" />
      </div>
      <div class="itemImg">
        <img :src="require('../assets/12.png')" />
      </div>
      <div class="itemImg">
        <img :src="require('../assets/13.png')" />
      </div>
      <div class="itemImg">
        <img :src="require('../assets/14.png')" />
      </div>
      <div class="itemImg">
        <img :src="require('../assets/15.png')" />
      </div>
    </div>
    <div class="line">
      <div class="lineHead">
        <div>帮瀛拟投资争议标的额在<b>5000万元</b>以上，</div>
        <div>有<b>胜诉依据</b>，</div>
        <div>并有<b>回款可能性</b>的争议案件。</div>
        <div class="lineCircle"></div>
      </div>
      <div class="lineDec">
        <div style="margin-bottom: 1rem"><b>帮瀛诉讼投资流程</b></div>
        <div style="line-height: 1.5rem">当事人或律师只需提供案件的材料，帮瀛风控部及投管委将根据背景调查及风险评估结果，为项目定制投资方案及管理方案。</div>
        <div style="margin-bottom: 3rem"><b>帮瀛投资将覆盖项目的全部或部分成本</b></div>
      </div>
      <div class="lineMode">
        <div>
          <div class="lineActive" style="margin-right: 0.5rem">材料收集</div>
          <div class="numLine" style="border-bottom: 4px solid #002c86">
            <div class="numCircle" style="background: #002c86;">01</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin: 0 0.5rem">背景调查风险评估</div>
          <div class="numLine">
            <div class="numCircle">02</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin: 0 0.5rem">项目签约</div>
          <div class="numLine">
            <div class="numCircle">03</div>
          </div>
        </div>
        <div>
          <div class="lineNormal" style="margin-left: 0.5rem">投后管理</div>
          <div class="numLine">
            <div class="numCircle" style="left: 0.5rem">04</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgModule">
      <div class="bgTitle">
        帮瀛业务领域主要包括：
      </div>
      <div class="bgRow">
        <div class="bgRowLeft">
          国内商事诉讼
        </div>
        <div class="bgRowCenter">
          国内外仲裁
        </div>
        <div class="bgRowRight">
          <div class="bgRt">
            强制执行
          </div>
          <div class="bgRb">
            破产重整
          </div>
        </div>
      </div>
      <div class="bgRow">
        <div class="bgBar">知识产权侵权</div>
        <div class="bgBarNext">反垄断及不正当竞争</div>
      </div>
      <div class="bgRow">
        <div class="bgBar" style="flex: 3">其他重大争议案件</div>
        <div class="bgBarNext" style="flex: 2">特殊资产处置</div>
      </div>
      <div class="bgText">
        <ul>
          <li>帮瀛TPF投资范围将覆盖项目的部分或全部成本，根据背景调查及风险评估结果，帮瀛将为项目定制投资方案。</li>
          <li>帮瀛更倾向于投资处于纠纷解决早期的案件，同等条件下，未诉/未申请仲裁的案件将获得最大限度的投资金额或投资范围。</li>
        </ul>
      </div>
    </div>
    <div class="scan">
      <div class="scanTop">
        <div class="scanHead">
          提交案件信息
        </div>
        <div class="scanImg">
          <img :src="require('../assets/scan.png')" style="width: 8rem; margin-bottom: 1rem; padding: 0.8rem; border: 1px solid #b2b2b2;" />
          <div>
            ——扫码添加微信提交信息——
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottomText">Copyright © 2016帮瀛法务 京ICP备15051201号-2</div>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .topbar {
    background-color: #002C86;
    padding-bottom: 2rem;
    position: relative;
    .topbarText {
      padding: 1.5rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
      font-size: 0.8rem;
      font-weight: 400;
      color: #ffffff;
      div {
        line-height: 1.8rem;
      }
      .lineSmall {
        width: 1.6rem;
        height: 0.2rem;
        background: #fff;
        margin-top: 1rem;
      }
    }
    .rightNews {
      position: fixed;
      z-index: 9;
      top: 12.4rem;
      right: 0;
      .rightNewItem {
        color: #fff;
        background: #0f1ba0;
        padding: 0.1rem 0.2rem;
        font-size: 0.4rem;
        margin-bottom: 0.3rem;
        font-weight: bold;
        box-shadow: -0.03rem 0.03rem 0.05rem #333;
        border-top-left-radius: 0.10rem;
        border-bottom-left-radius: 0.10rem;
        cursor: pointer;
      }
    }
  }
  .profile {
    padding: 1.5rem;
    .pHead {
      font-size: 1.8rem;
      color: #002c86;
      margin-top: 1rem;
      font-weight: bold;
    }
    .pSub {
      font-size: 1.4rem;
      color: #002c86;
      font-weight: bold;
      margin-bottom: 1.2rem;
    }
    .pDec {
      font-size: 0.6rem;
      color: #434343;
      line-height: 1.5rem;
    }
  }
  .timeInfo {
    margin-top: 4rem;
    background-color: #002C86;
    position: relative;
    height: 15rem;
    .timeImg {
      padding: 0 1.5rem;
      background-repeat: no-repeat;
      background-size: 22rem auto;
      background-position-x: -2rem;
      height: 17.4rem;
      position: absolute;
      top: -2.6rem;
    }
    .timeText {
      text-align: left;
      margin: 0 auto;
      color: #fff;
      .timeHead {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .timeSub {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .timeNum {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1.2rem;
      .timeNumLeft {
        width: 9.8rem;
        border-left: 2px solid #fff;
        padding-left: 1rem;
      }
      .timeNumRight {
        padding-left: 1rem;
        border-left: 2px solid #fff;
      }
      .timeNumBig {
        font-size: 1.6rem;
        font-weight: bold;
        span {
          font-size: 10px;
          font-weight: normal;
          position: relative;
          top: -1rem;
          margin-left: 0.3rem;
        }
      }
      .timeNumSmall {
        font-weight: normal;
        transform: scale(0.5) ;/* font-size: 16*0.5=8px  */
        transform-origin: left top;/* 调整位置*/
      }
    }
  }
  .staticImg {
    background: url("../assets/bg.png") repeat;
    padding: 1.5rem;
    .itemImg {
      text-align: center;
      margin-bottom: 2rem;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .line {
    padding: 1.5rem;
    .lineHead {
      color: #002C86;
      font-size: 1.2rem;
      margin-top: 2rem;
      div {
        margin-bottom: 0.25rem;
      }
      .lineCircle {
        width: 10px;
        height: 10px;
        border: 3px solid #002c86;
        border-radius: 10px;
        margin-top: 2rem;
      }
    }
    .lineDec {
      margin-top: 2rem;
      color: #434343;
      font-size: 0.9rem;
    }
    .lineMode {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div {
        flex: 1;
        text-align: center;
      }
      .lineNormal {
        padding: 0.5rem 0;
        border: 2px solid #b5b5b5;
        font-size: 0.8rem;
        font-weight: 400;
        color: #434343;
        margin: 0 auto;
        height: 3rem;
        display:flex;
        align-items: center;
        justify-content: center;
      }
      .lineActive {
        padding: 0.5rem 0;
        font-size: 0.8rem;
        font-weight: 700;
        color: #ffffff;
        background: #002c86;
        border: 2px solid #002c86;
        margin: 0 auto;
        height: 3rem;
        display:flex;
        align-items: center;
        justify-content: center;
      }
    }
    .numLine {
      border-bottom: 4px solid #777;
      margin-top: 1rem;
    }
    .numCircle {
      width: 20px;
      height: 20px;
      margin: 0 auto;
      background: #777;
      color: #fff;
      font-weight: bold;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      border-radius: 20px;
      position: relative;
      top: 10px;
    }
  }
  .bgModule {
    padding: 1.5rem;
    text-align: left;
    margin-top: 2rem;
    .bgTitle {
      font-size: 1rem;
      font-weight: 900;
      color: #434343;
      margin-bottom: 1.5rem;
    }
    .bgRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div {
        flex: 1;
        font-size: 0.8rem;
        color: #fff;
        font-weight: 400;
        text-align: center;
      }
      .bgRowLeft, .bgRowCenter {
        height: 4.5rem;
        line-height: 4.5rem;
        margin-right: 0.5rem;
        background: #002c86;
      }
      .bgRt {
        height: 2rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
        background: #002c86;
      }
      .bgRb {
        height: 2rem;
        line-height: 2rem;
        background: #002c86;
      }
      .bgBar {
        height: 2rem;
        line-height: 2rem;
        margin-top: 0.5rem;
        background: #002c86;
        margin-right: 0.5rem;
        flex: 2;
      }
      .bgBarNext {
        height: 2rem;
        line-height: 2rem;
        margin-top: 0.5rem;
        background: #002c86;
        flex: 3
      }
    }
    .bgText {
      margin-top: 2rem;
    }
    .bgText ul {
      margin-left: 1rem;
    }
    .bgText ul li {
      font-size: 0.8rem;
      font-weight: 400;
      text-align: left;
      color: #626262;
      list-style-type: disc;
      line-height: 1.5rem;
    }
  }
  .scan {
    text-align: center;
    margin-top: 8rem;
    background: #053084;
    height: 12rem;
    .scanTop {
      position: relative;
      top: -6rem;
    }
    .scanHead {
      color: #002C86;
      font-size: 1.4rem;
      font-weight: bold;
    }
    .scanHead {
      margin-bottom: 1.5rem;
    }
    .scanImg {
      text-align: center;
      color: #fff;
    }
  }
  .bottom {
    background-color: #03296e;
    text-align: center;
    .bottomText {
      font-size: 0.6rem;
      color: #fff;
      padding: 2rem 0;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
</style>